
export default {
    data: function () {
        return {
            isShow: false,
            isShow2: false,
            isShow3: false,
        };
    },

    props: {
        msg: String,
    },
}
