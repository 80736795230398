<template>
    <div class="wrapper">
        <div class="main-text">
            <h2>Naši odborní konzultanti</h2>
        </div>
        <div class="container">
            <Splide :has-track="false" aria-label="" class="splide"
                data-splide='{"type":"loop","perPage":1,"pagination":false}'>
                <SplideTrack>
                    <SplideSlide>
                        <article>
                            <div class="person">
                                <img src="../assets/petr.webp" alt="">
                            </div>
                            <div class="text">
                                <h2>PETR KONEČNÝ</h2>
                                <p>
                                    Petr se specializuje na <b>strategii HR managementu.</b> Vyladí vaši <b>organizační
                                        strukturu</b>,
                                    pomůže zvýšit výkonnost díky
                                    optimálnímu nastavení systému <b>řízení výkonnosti</b>. Přispěje k motivovanosti
                                    vašich
                                    pracovníků díky efektivně nastavenému
                                    <b>kariérnímu managementu.</b>
                                </p>

                            </div>
                        </article>
                    </SplideSlide>
                    <SplideSlide>
                        <article>
                            <div class="person">
                                <img src="../assets/lucie.webp" alt="">
                            </div>
                            <div class="text">
                                <h2>LUCIE MADĚRYČOVÁ</h2>
                                <p>
                                    Lucie se specializuje na <b>hodnocení lidí</b>. Efektivně s vámi nastaví <b>systém
                                        vzdělávání
                                        a rozvoje</b> vašich pracovníků, pomůže
                                    vám zjistit a udržet <b>motivaci vašich lidí</b> a budovat <b>správnou organizační
                                        kulturu</b>, ve
                                    které se bude vašim lidem dobře
                                    pracovat.
                                </p>


                            </div>
                        </article>
                    </SplideSlide>
                    <SplideSlide>
                        <article>
                            <div class="person">
                                <img src="../assets/tereza.webp" alt="">
                            </div>
                            <div class="text">
                                <h2>TEREZA SKŘIVANOVÁ</h2>
                                <p>
                                    Tereza se specializuje na <b>nábor a výběr lidí</b>. Správně s vámi nastaví proces
                                    <b>nástupu
                                        a zaškolování nového pracovníka</b>.
                                    Pomůže vám efektivně nastavit <b>systém vzdělávání a rozvoje</b> a systematicky
                                    <b>rozvíjet
                                        vaši značku zaměstnavatele</b>, aby
                                    přitáhla ty správné lidi.
                                </p>
                            </div>
                        </article>
                    </SplideSlide>
                </SplideTrack>
                <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev"></button>
                    <button class="splide__arrow splide__arrow--next"></button>
                </div>
            </Splide>
        </div>
    </div>

</template>

<script>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import { defineComponent } from 'vue'
import '@splidejs/splide/css/sea-green';

export default defineComponent({
    components: {
        Splide,
        SplideSlide,
        SplideTrack,
    },

});
</script>

<style scoped>
.splide {
    padding: 0;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F5F5F4;
    position: relative;
    z-index: 10;
    padding-bottom: 100px;
}

.wrapper::before {
    content: '';
    position: absolute;

    top: -100px;
    height: 100px;
    clip-path: ellipse(60% 100% at 50% 100%);

    left: 0px;
    right: 0px;
    box-sizing: border-box;
    max-width: 1920px;
    margin: 0 auto;
    background: #F5F5F4;
    z-index: -15;
}

.container {
    width: 98%;
    max-width: 1400px;
    position: relative;
}

.main-text {}

.main-text h2 {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2rem, 10vw, 2.75rem);
    margin: 10% 0%;
    color: var(--text-secondary);
    text-transform: uppercase;
}

article {
    margin: 1rem auto;
    padding: 2.5%;
    width: 80%;
    height: auto;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #287BD330;
    gap: 2rem;
    position: relative;
}

article .person {
    width: 100%;
    height: 100%;
    position: relative;
    top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

article .person img {
    min-width: 250px;
    width: 90%;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

article .text {
    color: var(--text-primary);
    height: 100%;
    width: 100%;
    text-align: left;
}

article div h2 {
    font-size: clamp(1.5rem, 10vw, 2.5rem);
    text-align: center;
    margin: 2rem 0rem;
    text-transform: uppercase;
    color: var(--text-primary);
}

article div p {
    font-size: clamp(1rem, 4vw, 1.6rem);
    text-align: center;
    margin: 0rem .5rem .5rem .5rem;
}

@media screen and (min-width: 840px) {
    article {
        display: flex;
        flex-direction: row;
        width: 80%;
        max-width: 900px;
        max-height: 500px;
        padding: 5%;
    }

    article .person {
        flex: 1;
        top: 0rem;
    }

    article .person img {
        width: 100%;
        top: 0;
    }

    article .text {
        flex: 1;
    }

    article div h2 {
        text-align: start;
        margin: 2rem 0rem 1rem 0rem;
    }

    article div p {
        text-align: start;
    }

    .wrapper::before {
        top: -150px;
        height: 300px;
        clip-path: ellipse(45% 100% at 50% 100%);
    }

}

.splide__arrow {
    transform: none;
    top: 0;
    bottom: 0;
    margin: auto;
}

.splide__arrow--next::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    z-index: 10;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid #287BD330;
    transform: none;
}

.splide__arrow--prev::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    z-index: 10;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid #287BD330;
    transform: none;
}

@media screen and (min-width: 1280px) {
    .wrapper::before {
        top: -200px;
        height: 600px;
        clip-path: ellipse(55% 100% at 50% 100%);
    }

    .splide__arrow--next::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: 0;
        z-index: 10;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 50px solid #287BD330;
        transform: none;
    }

    .splide__arrow--prev::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        z-index: 10;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-right: 50px solid #287BD330;
        transform: none;
    }
}
</style>