import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_CardsComponent = _resolveComponent("CardsComponent")!
  const _component_CarouselComponent = _resolveComponent("CarouselComponent")!
  const _component_Cards2Component = _resolveComponent("Cards2Component")!
  const _component_RefsComponent = _resolveComponent("RefsComponent")!
  const _component_SupportersComponent = _resolveComponent("SupportersComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderComponent),
    _createVNode(_component_CardsComponent),
    _createVNode(_component_CarouselComponent),
    _createVNode(_component_Cards2Component),
    _createVNode(_component_RefsComponent),
    _createVNode(_component_SupportersComponent),
    _createVNode(_component_FooterComponent)
  ], 64))
}