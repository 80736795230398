
import HeaderComponent from './components/HeaderComponent.vue'
import CardsComponent from './components/CardsComponent.vue'
import CarouselComponent from './components/CarouselComponent.vue'
import Cards2Component from './components/Cards2Component.vue';
import RefsComponent from './components/RefsComponent.vue'
// import InterimRefsComponent from './components/InterimRefsComponent.vue'
import SupportersComponent from './components/SupportersComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  components: {
    HeaderComponent,
    CardsComponent,
    CarouselComponent,
    Cards2Component,
    // InterimRefsComponent,
    RefsComponent,
    SupportersComponent,
    FooterComponent
  }
}

